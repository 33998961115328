













import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, State, namespace } from 'vuex-class';
import { UserSubscription } from '@/store/modules/subscription/subscription.state';
import ApplicationList from './components/ApplicationList.vue';
import Container from '@/components/Container.vue';
import Card from '@/components/Card.vue';
import { RootState } from '../../store/store';

@Component<HomePage>({
  components: {
    ApplicationList,
    Container
  }
})
export default class HomePage extends Vue {
  @Action('subscription/getMySubscriptions')
  public getMySubscriptions!: () => void;

  @Action('profile/getMe')
  public getMe!: () => void;

  @State((state: RootState) => state.profile.profile)
  public profile!: any;

  @State((state: RootState) => state.subscription.subscriptions)
  public userSubscriptions!: UserSubscription[];

  @State(
    (state: RootState) => state.subscription.apiState.getMySubscriptions.loading
  )
  public getMySubscriptionsLoading!: boolean;

  public created() {
    if (!this.profile) {
      this.getMe();
    }
    this.getMySubscriptions();
  }
}
