























import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import {
  Product,
  ApplicationState,
  Application
} from '@/store/modules/application/types/application.types';
import Card from '@/components/Card.vue';
import { RootState } from '@/store/store';
import { UserSubscription } from '@/store/modules/subscription/subscription.state';
import ApplicationCard from './ApplicationCard.vue';
import { JAAS_APP } from '../../../jbi-shared/types/jaas-app.types';
import { GroupSubscriptionStatus } from '@/jbi-shared/types/jaas-oauth2.types';

@Component({
  components: {
    Card,
    ApplicationCard
  }
})
export default class ApplicationList extends Vue {
  @Prop({ type: Boolean, default: false })
  public isLoading!: boolean;

  @Prop()
  public userSubscriptions!: UserSubscription[] | null;

  @Action('application/getApplications')
  public getApplications!: () => Promise<void>;

  @State((state: RootState) => state.application.productApplications?.products)
  public products!: Product[];

  @State(
    (state: RootState) => state.application.productApplications?.applications
  )
  public applications!: Application[];

  get getProductsState() {
    return (this.$store.state.application as ApplicationState).apiState
      .getProducts;
  }

  getGroupSubscriptionStatus(appId: JAAS_APP, type: 'individual' | 'group') {
    return (
      this.userSubscriptions?.find(
        (s) =>
          s.applicationId === appId &&
          type === 'group' &&
          !!s.groupSubscriptionStatus
      )?.groupSubscriptionStatus === GroupSubscriptionStatus.ACTIVE
    );
  }

  getSubscription(appId: JAAS_APP, type: 'individual' | 'group') {
    return this.userSubscriptions?.find(
      (s) =>
        s.applicationId === appId &&
        ((type === 'individual' && s.subscriptionStatus) ||
          (type === 'group' && !!s.groupSubscriptionStatus))
    );
  }

  public mounted() {
    this.getApplications();
  }
}
