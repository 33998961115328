import { JAAS_APP } from '@/jbi-shared/types/jaas-app.types';
import { AccessToApplication } from '@/jbi-shared/types/jaas-product-access.types';
import axios from 'axios';

export const productAccessApi = {
  async getProductAccess(
    applicationName: Exclude<JAAS_APP, JAAS_APP.training | JAAS_APP.myjbi>
  ): Promise<boolean | null | undefined> {
    const response = await axios.post(
      `${process.env.VUE_APP_BACKEND_HOSTNAME}/product-access`,
      {
        applicationNames: [applicationName]
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    const productAccessData: AccessToApplication = response.data;
    return productAccessData[applicationName];
  }
};
