





























































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import Card from '@/components/Card.vue';
import { UserSubscription } from '@/store/modules/subscription/subscription.state';
import {
  Application,
  Product
} from '@/store/modules/application/types/application.types';
import {
  JAAS_APP,
  JAAS_APP_CARD_DETAILS
} from '@/jbi-shared/types/jaas-app.types';
import { AccessToApplication } from '@/jbi-shared/types/jaas-product-access.types';

import dayjs from 'dayjs';
import {
  GroupSubscriptionStatus,
  SubscriptionStatus
} from '../../../jbi-shared/types/jaas-oauth2.types';

import axios from 'axios';
import { productAccessApi } from '@/api/product-access.api';

@Component({
  components: {
    Card
  }
})
export default class ApplicationCard extends Vue {
  @Prop()
  public subscription!: UserSubscription | null;

  @Prop()
  public application!: Application;

  @Prop()
  public products!: Product[];

  @Prop({ type: Boolean, default: false })
  public isLoading!: boolean;

  public productAccess: boolean | null | undefined = false;

  created() {
    this.initializeProductAccess();
  }

  async initializeProductAccess() {
    const applicationName = this.application.id as Exclude<
      JAAS_APP,
      JAAS_APP.training | JAAS_APP.myjbi
    >;
    const result = await productAccessApi.getProductAccess(applicationName);
    this.productAccess = result;
  }

  get productStatus() {
    if (this.isGroupSub) {
      return true;
    }
    if (!this.products) {
      return false;
    }

    if (
      this.products
        .filter((res) => res.applicationId === this.application.id)
        .find((res) => res.isActive === true && res.isDeleted === false)
    ) {
      return true;
    }
    return false;
  }

  get expiryMessage() {
    if (
      (this.subscription &&
        this.subscription?.subscriptionStatus === SubscriptionStatus.ACTIVE) ||
      this.subscription?.groupSubscriptionStatus ===
        GroupSubscriptionStatus.ACTIVE
    ) {
      return this.subscription
        ? 'Subscription expires ' +
            dayjs(this.subscription.endedAt).format('D MMM YYYY')
        : '';
    }
    return;
  }

  get jaasAppDetails() {
    return JAAS_APP_CARD_DETAILS;
  }

  public getApplicationImage(applicationId: JAAS_APP) {
    if (applicationId === JAAS_APP.paces) {
      return require(`@/assets/images/subscriptions/jbipaces_thumbnail_img@2x.png`);
    }

    if (applicationId === JAAS_APP.sumari) {
      return require(`@/assets/images/subscriptions/jbisumari_thumbnail_img@2x.png`);
    }

    if (applicationId === JAAS_APP.training) {
      return require(`@/assets/images/subscriptions/jbitraining_thumbnail_img@2x.png`);
    }
    return '';
  }

  public getApplicationLogo(applicationId: JAAS_APP) {
    if (applicationId === JAAS_APP.paces) {
      return require(`@/jbi-shared/assets/images/logo_paces@2x.png`);
    }

    if (applicationId === JAAS_APP.sumari) {
      return require(`@/jbi-shared/assets/images/jbisumari_card_img.png`);
    }

    if (applicationId === JAAS_APP.training) {
      return require(`@/jbi-shared/assets/images/jbitraining_card_img.png`);
    }
    return '';
  }
  public getApplicationDescription(applicationId: JAAS_APP) {
    return JAAS_APP_CARD_DETAILS[applicationId].applicationDescription;
  }

  public launchApplication(): void {
    window.location.href = `${process.env.VUE_APP_BACKEND_HOSTNAME}/${this.application.id}/launch-app`;
  }

  get isSubscribed() {
    return (
      this.subscription?.subscriptionStatus === SubscriptionStatus.ACTIVE ||
      this.subscription?.groupSubscriptionStatus ===
        GroupSubscriptionStatus.ACTIVE
    );
  }

  get hasAccess(): boolean {
    const applicationName = this.application.id as Exclude<
      JAAS_APP,
      JAAS_APP.training | JAAS_APP.myjbi
    >;
    if (this.isSubscribed === true && this.productStatus) {
      return true;
    } else if (
      this.productAccess !== null &&
      this.productAccess !== undefined
    ) {
      return this.productAccess;
    } else if (this.productAccess === null) {
      this.showProductAccessApplicationError(applicationName);
      return false;
    } else {
      this.showProductAccessError(applicationName);
      return false;
    }
  }

  get isIndividualSub() {
    return this.subscription?.subscriptionStatus === SubscriptionStatus.ACTIVE;
  }

  get isGroupSub() {
    return (
      this.subscription?.groupSubscriptionStatus ===
      GroupSubscriptionStatus.ACTIVE
    );
  }

  public showProductAccessError(
    applicationName: Exclude<JAAS_APP, JAAS_APP.training | JAAS_APP.myjbi>
  ) {
    this.$buefy.toast.open({
      message: `Error fetching application access from ${applicationName.toUpperCase()}. Refresh to try again.`,
      type: 'is-danger'
    });
  }

  public showProductAccessApplicationError(
    applicationName: Exclude<JAAS_APP, JAAS_APP.training | JAAS_APP.myjbi>
  ) {
    this.$buefy.toast.open({
      message: `${applicationName.toUpperCase()} did not respond. Refresh to try again.`,
      type: 'is-danger'
    });
  }
}
