import { DateOrString } from './full-document-revision-object.types';

export enum JAAS_OAUTH2_SCOPES {
  openid = 'openid',
  profile = 'profile',
  email = 'email',
  // TODO: remove cplus_permissions
  cplus_permissions = 'cplus_permissions',
}

export enum SubscriptionStatus {
  BLOCKED = 'Blocked',
  CONVERTEDTOWK = 'Converted_To_WK',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
}
export enum UserSubscriptionStatus {
  VALID = 'valid',
  INVALID = 'invalid',
}

export enum GroupSubscriptionStatus {
  NONEXISTENT = 'Nonexistent',
  INACTIVE = 'Inactive',
  ACTIVE = 'Active',
}

export interface JaasClientUserDetails {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  endedAt?: DateOrString;
  username: string;
  subscriptionStatus?: SubscriptionStatus;
  groupSubscriptionStatus: GroupSubscriptionStatus;
  groupSubscriptionEndedAt?: DateOrString;
}

export interface SubscriptionStatusPayload {
  productId: number | null;
  subscriptionStatus: UserSubscriptionStatus;
  expireAt: DateOrString | null;
  calledAt: DateOrString;
}

export interface UserListSubscriptionStatusPayload {
  isValidUser: boolean;
  subscriptionStatus?: UserSubscriptionStatus;
}

export interface OicPayload {
  // reference: https://tools.ietf.org/html/rfc7519#section-4.1
  // openid
  iss?: string;
  sub?: string;
  aud?: string;
  exp?: number;
  iat?: number;
  // email
  email?: string;
  email_verified?: boolean;
  // profile
  name?: string;
  family_name?: string;
  given_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  picture?: string;
  website?: string;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  updated_at?: number;
}

export enum ErrorCode {
  FORBIDDEN_RESOURCE = 4030, // Forbidden resource
  EMAIL_NOT_VERIFY = 4031, // email not verify
  INVALID_USER_ROLE = 4032, // user role no permitted
  MISSING_REQUIRED_ATTRIBUTE_VALUE = 4033, // missing required attribute value
}
